import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import "./ActualNewsContent.scss";
import ImageBellApp from "../../images/illustrations/bell.push.news.svg";
import ImageSendPush from "../../images/illustrations/send.push.news.png";
import ImageInfoPush from "../../images/illustrations/info.push.news.svg";

const ActualNewsContent = ({ data }) => {
	return (
		<div className={data.reverse ? "new-content reverse" : "new-content"}>
			<div className="text-content">
				<div>
					<h2>{data.subtitle}</h2>
				</div>
				<div>
					<h1>{data.title}</h1>
				</div>
				<div>
					<p>{data.paragraph}</p>
					<ul>
						{data?.items &&
							data?.items?.map((item, index) => {
								return <li key={index}>{item}</li>;
							})}
					</ul>
				</div>
				<div>{/*<p className="link">{data.linktext}</p>*/}</div>{" "}
			</div>
			<div className="image-content">
				{
					{
						1: (
							<img
								className="new-image send"
								alt="imagen 1"
								src={ImageSendPush}
								// effect="blur"
							/>
						),
						2: (
							<img
								className="new-image"
								alt="imagen 2"
								src={ImageBellApp}
								// effect="blur"
							/>
						),
						3: (
							<img
								className="new-image"
								alt="imagen 3"
								src={ImageInfoPush}
								// effect="blur"
							/>
						),
					}[data.id]
				}
			</div>
		</div>
	);
};

export default ActualNewsContent;
